tr.hide-table-padding td {
    padding: 0;
}

.expand-button {
    position: relative;
    text-align: right;
}

.accordion-toggle .expand-button:after
{
    position: absolute;
    left:.75rem;
    top: 50%;
    transform: translate(0, -50%);
    text-align: right;
}

