// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Table
@import 'table';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


@import '~select2/dist/css/select2.css';
//@import '~select2-theme-bootstrap4/dist/select2-bootstrap.css';

@import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';
@import '~datatables.net-select-bs4/css/select.bootstrap4.min.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
@import '~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css';

@import '~jquery-datatables-checkboxes/css/dataTables.checkboxes.css';


@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


// @import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';

.select2-selection__rendered {
    font-weight: normal;
}

.dataTable {
    font-weight: normal;
}

tbody tr:hover{
    background-color: rgba(184, 184, 184, 0.301) !important;
}

/* Chrome, Safari, Edge, Opera */
input.noarrow::-webkit-outer-spin-button,
input.noarrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.noarrow[type=number] {
  -moz-appearance: textfield;
}

.selected {
  color: darkblue !important;
}

.green {
    color: rgb(68, 184, 68);
}

.popoverlink {
    color: rgb(28, 48, 48);
    text-decoration: none;
  }

.popover {background-color: rgb(216, 255, 212);}
// .popover.bottom .arrow::after {border-bottom-color: tomato; }
// .popover-content {background-color: tomato;}

// #cover {position: fixed; height: 100%; width: 100%; top:0; left: 0; background: rgb(255, 255, 255); z-index:9999;}
#content { visibility:hidden; }


.loader {
  width: calc(65%);
  height: 50vh;
  margin-top: 2rem;
  margin-left: 9rem;
  display: flex;
  background: white;
  padding: 150px;
  //box-shadow: 0px 40px 60px -20px rgba(0, 0, 0, 0.2);
}

.loader span {
  display: block;
  width: 20px;
  height: 20px;
  background: #eee;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.loader span:nth-child(2) {
  background: #018ebd;
}

.loader span:nth-child(3) {
  background:#89B917;;
}

.loader span:nth-child(4) {
  background: #eee;
}

.loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}


tr.selected {
    color: white !important;
    background-color: #018ebd !important;
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

body {
    background-color: #018ebd !important;
}

body, body *:not(html):not(style):not(br):not(tr):not(code) {
  font-family: Avenir, Helvetica, sans-serif;
  box-sizing: border-box;
}

.vertical-nav {
    min-width: 16rem;
    width: 16rem;
    height: calc(100vh - 7rem);
    position: relative;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
  }

.holder {
    position: relative;
    margin-left: 2%;
    // margin-left: 10%;
    margin-right: 5%;
}

  .page-content {
    width: calc(100% - 20rem);
    margin: 2rem;
  }

  /* for toggle behavior */

  #sidebar.active {
    display: none;
  }

  .page-content.active {
    width: 100%;
    margin-top: 2rem;
  }

  #header {
      height: 7rem;
      max-height: 7rem;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(127,168,25,1) 100%);
  }

  @media (max-width: 768px) {

    .loader {
      width: 100vw;
      height: 50vh;
      margin-left: 0;
      margin-right: 0;
      margin-top: 15vh;
      display: flex;
      background: white;
      padding: 150px;
      //box-shadow: 0px 40px 60px -20px rgba(0, 0, 0, 0.2);
    }

    .holder {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .page-content {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 15vh;
        font-size: large;
      }

    #sidebar {
        display: none;
    }
    #sidebar.active {
      position: relative;
      margin-top: 15vh;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10vh;
      width: 100%;
      display: block;
      height: 23rem;
      font-size: large;
    }
  }

.text-egp {
    color: #89B917;
}



a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  padding-left: 1.4rem;
  transition: all 0.3s ease;
  background-color:  #018ebd !important;
  color: white !important;
 // background-color: #dae0e5 !important;
}

a.bg-white:hover i,
a.bg-white:focus i
{
  transition: all 0.3s ease;
  color: white !important;
  padding-right: 0.7rem;
}

h1, h2 {
  color: #343a40;
  font-family: 'Montserrat';
  font-size: 24px;
}

h3, h4 {
  color: #018ebd;
  font-family: 'Montserrat';
  font-size: 18px;
}

.btn-primary {
  color: white !important;
  background-color: #89B917 !important;
  border-width: 2px;
  border-color:  #89B917;
}

.btn-primary:hover,
.btn-primary:focus  {
  color: #89B917 !important;
  background-color: white !important;
  border-width: 2px;
  border-color:  #89B917;
}

.btn-secundary {
  color: #89B917 !important;
  background-color: white !important;
  border-color: #89B917;
}

.form-control::-moz-placeholder {
    color: #cccccc;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #cccccc;
    opacity: 1;
}

.form-control::placeholder {
    color: #cccccc;
    opacity: 1;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #cccccc;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #cccccc;
}
.ck.ck-editor__editable > .ck-placeholder::before {
    color: #cccccc;
}


